.story-timeline-heading {
	padding-top: 50px;
	padding-bottom: 50px;

	h4 {
		margin-top: 20px;
		margin-bottom: 30px;
		text-transform: uppercase;
		font-size: 30px;
	}
}