// Brand Colours
$primary: #002d72;
$secondary: #009fdf;
$success: #E8E8E8;
$info: #FD6A27;
$warning: #D33732;
$dark-green: #084346;
$light-green: #2BA068;
$gold: #D4A839;
$orange: #F15E2B;

// Black and Greyscale
$gray-100: #636363;
$gray-200: #d2d2d2;
$gray-300: #fbfafa;
$gray-400: #e5e8eb;
$gray-500: #7a7a7a;
$gray-600: #3F3F40;
$gray-700: #F4F4F4;
$white: #fff;
$offwhite: #F0F0F0;

$body-bg: white;
$body-color: black;

$border-radius: 50px;

// Grid
$grid-columns:	24;

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1366px
) !default;

// Fonts
$font-family-sans-serif: 'futura-pt', sans-serif;
$font-family-monospace:  'futura-pt-bold', sans-serif;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.25rem; // 16px
$font-weight-base: 400;

$h1-font-size: 2.75rem; // 44px
$h2-font-size: 2.25rem; // 36px
$h3-font-size: 1.5rem; // 24px
$h4-font-size: 22px;

$headings-margin-bottom: 1rem;

// Nav
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 0;
$navbar-brand-padding-y: 0;

// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.5rem;

// Buttons
$btn-padding-y: .75rem;
$btn-padding-x: 1.5rem;

$btn-border-radius:           10px;
$btn-border-radius-lg:        10px;
$btn-border-radius-sm:        10px;

$btn-font-size: 1.115rem;

// Modal
$modal-md: 960px;

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap";