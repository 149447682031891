// Sustainability
.sustainability {
	@media (max-width: 767px) {
		background: transparent !important;
	}

	@media (min-width: 768px) {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-top: 100px;
		padding-bottom: 100px;
		margin-top: 100px;
		margin-bottom: 100px;
	}

	&--dark-green {
		h1, h2, h3, h4, h5 {
			color: $dark-green;
		}
	}

	&--light-green {
		h1, h2, h3, h4, h5 {
			color: $light-green;
		}
	}
}