.story-statistics {
	background-color: white;
	color: #1F1F1F;
	border-radius: 30px;
	margin-top: 50px;
	padding: 50px !important;

	@media(min-width: 768px) {
		padding-left: 150px !important;
  	padding-right: 150px !important;
	}

	h1, h2, h3, h4 {
		color: $primary;
		font-family: $font-family-sans-serif;
	}

	h3 {
		font-size: 40px;
	}

	.lead {
		font-size: 22px;
	}

	.story-stat {
		border-bottom: 2px solid $primary;
		padding-bottom: 100px;
	}

	.story-stat:last-child {
		padding-top: 100px;
		border: none !important;
	}

	&__percentage {
		font-size: 80px;
		font-family: $font-family-monospace;
		color: $secondary;
		text-align: center;

		p {
			margin-bottom: 0;
		}
	}

	&__info {
		text-transform: uppercase;
		text-align: center;
		color: $primary;
	}

	&__stat-content {
		p {
			margin-bottom: 0;
		}
		
		@media (min-width: 992px) {
			padding-left: 100px !important;
		}
	}
}