.story-full-width-content {
	padding-top: 50px;
	padding-bottom: 50px;

	h1, h2, h3, h4 {
		font-family: $font-family-sans-serif;

		span {
			color: $secondary;
		}
	}
}
