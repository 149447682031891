// Requirement cards
.requirement-card {
	padding: 0 15px;

	&__card {
		background: white;
		border-radius: 15px;
		font-size: 16px;
		height: 600px;
		padding-top: 15px;

		img {
			height: 260px;
    	margin: 15px auto;
		}

		p {
			margin-bottom: 0 !important;
		}
	}

	&__info {
		padding: 2rem;
		text-align: center;
		height: 280px;
		position: relative;

		h4 {
			font-family: $font-family-sans-serif;
		}

		a {
			@include borderedLink;
			position: absolute;
	    bottom: 30px;
	    left: 0;
	    right: 0;
	    margin-left: auto;
	    margin-right: auto;
	    width: 120px;
		}
	}
}
