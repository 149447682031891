// Table
.table-wrapper {
	border: 2px solid $primary;
  padding: 5px 0;
  border-radius: 10px;
}

.table-striped tbody tr:nth-of-type(even) {
	background-color: white !important;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 159, 223, 0.45) !important;
}

.table-striped thead th {
	text-transform: uppercase;
}

.colour-select {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 2px solid black;
	margin-right: 5px;
	display: inline-block;

	&--red {
		background-color: #C03633;
	}

	&--blue {
		background-color: #499DD5;
	}

	&--light-blue {
		background-color: #4DABE9;
	}

	&--green {
		background-color: #48986D;
	}

	&--yellow {
		background-color: #FCF150;
	}

	&--white {
		background-color: white;
	}
}