// About us quality products
.quality-products {
	padding-bottom: 100px;

	&__carousel {
		background-color: $primary;
		text-align: center;
		padding: 80px;

		&:before {
			content: "";
	    position: absolute;
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/solutions-menu-before.svg);
	    background-size: cover;
	    background-position: bottom left;
	    background-repeat: no-repeat;
	    top: -15px;
	    right: -9px;
	    width: 200px;
	    height: 200px;
	    z-index: 0;
	    transform: rotate(180deg);
		}

		img {
			max-width: 450px;
	    max-height: 350px;
	    width: auto !important;
		}
	}
}