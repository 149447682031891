// Icon grid
.icon-grid {
	color: white;
	background-image: url('/wp-content/themes/harrisonwipes/assets/images/blue-container-right-effect.png');
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 200px;

	&--icon {
		padding-bottom: 1.5rem;

		p {
			display: inline;
		}

		img {
			margin-right: 15px;
		}
	}
}