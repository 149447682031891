// Home two columns
#home-two-col {
	position: relative;

	@media(min-width: 992px) {
		&:before {
			content: '';
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/celebrate-badge.svg');
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			height: 73px;
	    width: 88px;
			left: 0;
	    right: 0;
	    margin-left: auto;
	    margin-right: auto;
	    z-index: 2;
		}
	}
}