// Contact us map
.iframe-container {
	position: relative;
	border-top: 10px solid white;
	border-bottom: 10px solid white;

	@media (min-width: 992px) {
		&:before {
			content: "";
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/home-hero-before.png);
	    background-position: top right;
	    background-repeat: no-repeat;
	    height: 300px;
	    width: 300px;
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    background-size: cover;
	    z-index: 9999;
		}
	}

	&__address {
		padding: 80px;
    background: white;

    @media (min-width: 992px) {
    	position: absolute;
	    right: 50px;
	    top: 50px;
	    box-shadow: 0px 3px 8px black;
    }

    @media (min-width: 992px) {
    	&:before {
				content: "";
		    background-image: url(/wp-content/themes/harrisonwipes/assets/images/address-before.png);
		    background-position: top right;
		    background-repeat: no-repeat;
		    height: 160px;
		    width: 160px;
		    position: absolute;
		    right: -10px;
		    top: -12px;
		    background-size: cover;
		    z-index: 9999;
			}
    }	
	}
}