.story-resources {
	background-color: white;
	margin-top: 50px;
	margin-bottom: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	color: $primary;
	text-align: center;

	h2, h3, h4 {
		color: $primary;
	}

	h3 {
		text-transform: uppercase;
	}

	h5 {
		color: $secondary;
	}

	.resources-carousel {
		margin-top: 50px;
	}

	.resources-carousel-wrap {
		border: 1px solid rgba(0, 45, 114, 0.42);
		border-radius: 30px;
		overflow: hidden;

		&__content {
			padding: 50px;
		}
	}
}