// Typography
body {
	font-family: $font-family-sans-serif !important;
	font-size: 18px !important;
}

h1, h2, h3, h4 {
	color: $primary;
	font-family: $font-family-monospace;
}

h1 {
	
}

h2 {
	
}

.text-right-dt {
	@media (min-width: 992px) {
		text-align: right;
	}
}

ol {
	margin: 0 !important;
  padding: 15px !important;
}

ul {
	margin-left: 0 !important;
	padding-left: 1rem !important;
}

li {
	margin-left: 0;
}

a {
	color: $secondary !important;
}

a:visited {
	color: $secondary;
}