// About us accreditations
.accreditations {
	color: white;
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;

	@media (min-width: 992px) {
		&:before {
			content: "";
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/secondary-before-lines.svg);
	    background-repeat: no-repeat;
	    position: absolute;
	    left: -20px;
	    bottom: -30px;
	    width: 400px;
	    height: 400px;
	    background-size: cover;
		}

		&:after {
			content: "";
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/after-shape.png);
	    background-repeat: no-repeat;
	    background-size: cover;
	    position: absolute;
	    height: 180px;
	    width: 180px;
	    top: 0;
	    right: 0;
		}
	}

	h2 {
		color: $primary;
	}

	&__box {
		background-color: white;
		padding: 50px;
		margin-top: 50px;
		border-radius: 15px;

		&__label {
			color: $primary;
			margin-top: 30px;
			font-weight: 700;
		}

		.col-lg-12:last-child {
			@media (min-width: 992px) {
				border-left: 3px solid $secondary;
			}
		}
	}
}