// Footer
footer {
	background-image: url('/wp-content/themes/harrisonwipes/assets/images/footer-bg.png');
	background-size: 870px;
	background-repeat: no-repeat;
	background-position: top right;
	color: white;
	@include afterShape;

	a {
		color: white !important;
		font-weight: 500;

		span {
			margin-right: 15px;
		}
	}

	p {
		margin-bottom: 15px !important;
	}

	ul {
  	list-style-type: none;
	}

	ul, li {
		padding-left: 0 !important;
	}

	#suppliers {
		img {
			max-width: 150px;
		}
	}
}

#footer-logo {
	margin-bottom: 2rem;
}