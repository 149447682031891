// Home sectors
.home-sectors {
	background-color: #EAF9FF;
	padding-top: 20px;
	padding-bottom: 20px;

	h2 {
		margin-bottom: 30px;
	}

	img {
		margin-bottom: 1rem;
		height: 80px;

		@media (max-width: 768px) {
			max-width: 90px;
		}
	}

	a {
		font-family: $font-family-monospace;
	}
}

.home-sectors__col {
	position: relative;
	padding-left: 45px !important;
	padding-right: 45px !important;
	margin-top: 15px;
	margin-bottom: 15px;

	@media (min-width: 1200px) {
		&:after {
			content: "";
	    height: 100%;
	    width: 50px;
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/sector-stroke.svg);
	    background-repeat: no-repeat;
	    position: absolute;
	    right: -25px;
	    top: 0;
	    background-position: center;
		}
	}

	@media (min-width: 1200px) {
		margin-top: 0;
		margin-bottom: 0;
	}

	@media (max-width: 768px) {
		max-width: 50% !important;
	}
}


.home-sectors__col:last-child:after {
	display: none;
}

.home-sectors__col:nth-of-type(odd) {
	a {
		color: $primary !important;
	}
}

.home-sectors__col:nth-of-type(even) {
	a {
		color: $secondary !important;
	}
}