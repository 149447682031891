// Brands our products
.brands-our-products {
	&--primary {
		h1, h2, h3, h4, h5, h6 {
			color: $primary;
		}

		.slick-prev {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-primary-left.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-prev:before {
			display: none !important;
		}

		.slick-next {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-primary-right.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-next:before {
			display: none !important;
		}
	}

	&--secondary {
		h1, h2, h3, h4, h5, h6 {
			color: $secondary;
		}

		.slick-prev {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-light-blue-left.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-prev:before {
			display: none !important;
		}

		.slick-next {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-light-blue-right.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-next:before {
			display: none !important;
		}
	}

	&--gray-600 {
		h1, h2, h3, h4, h5, h6 {
			color: $warning;
		}

		.slick-prev {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-warning-left.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-prev:before {
			display: none !important;
		}

		.slick-next {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-warning-right.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-next:before {
			display: none !important;
		}
	}

	&--info {
		h1, h2, h3, h4, h5, h6 {
			color: $info;
		}

		.slick-prev {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-info-left.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-prev:before {
			display: none !important;
		}

		.slick-next {
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-info-right.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.slick-next:before {
			display: none !important;
		}
	}
}