// Bullet strip
.bullet-strip {
	ul {
		-webkit-column-count: auto;
		-moz-column-count: auto;
	     column-count: auto;
		column-width: 20em;
	}

	li {
		display: inline-block;
	}

	h1, h2, h3, h4 {
		text-align: center;
		margin-bottom: 3rem;
	}

	&--dark-blue {
		background-color: $primary;
		border-top: 5px solid red;
		color: white;

		li {
			@include secondaryListItem;
			padding-left: 40px;
    			margin-bottom: 10px;
    			padding-right: 40px;
		}

		h1, h2, h3, h4 {
			color: white;

			strong {
				color: $secondary !important;
			}
		}
	}

	&--blue-bg {
		background-color: $primary;
		color: white;
		@include afterShapeSecondary;
		@include beforeShapeSecondary;

		li {
			@include secondaryListItem;
		}

		h1, h2, h3, h4 {
			color: white;

			strong {
				color: $secondary !important;
			}
		}
	}

	&.container-fluid--success {
		li {
			@include warningListItem;
		}

		h1, h2, h3, h4 {
			color: $gray-600;
			strong {
				color: $warning !important;
			}
		}
	}
}