// Single product card
.single-product-card {
	background-color: white;

	img {
		width: 100%;
	}

	&__info {
		padding: 3rem;
	}
}