.resources-carousel-product-landing-page-slide {
	padding: 30px;

	&__wrap {
		border: 1px solid $offwhite;
		text-align: center;
		color: $primary;
		border-radius: 30px;
		overflow: hidden;

		&__copy {
			padding: 30px;

			h4 {
				color: $orange;
				text-transform: uppercase;
				margin-top: 30px;
			}
		}
	}
}