.product-landing-page-hero {
	background-color: $offwhite;
	padding-top: 50px;
	padding-bottom: 50px;

	h1, h2, h3, h4, h5 {
		font-family: $font-family-sans-serif;
	}

	h1 {
		margin-bottom: 5px;
	}

	h2 {
		color: $orange;
	}
}