// Home brands
#home-brands {
	position: relative;

	&:before {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/brands-overlay.png');
		background-position: top left;
		background-repeat: no-repeat;
		height: 100px;
    width: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: cover;
	}

	&:after {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/brand-overlay-after.png');
		background-position: top right;
		background-repeat: no-repeat;
		height: 100px;
	  width: 100px;
	  position: absolute;
	  right: 0;
	  top: 0;
	  background-size: cover;
	}
}