.product-selector-tool {
  text-align: left;
  color: #002D72;
  font-weight: 600;
  font-family: 'futura-pt', sans-serif;
  font-size: 22px;

  h1, h2, h3 {
      color: #FD6A27;
  }
  .container {
      margin-top: 50px;
      max-width: 600px;
      margin: 0 auto;
      background: white;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 0 15px 0 0;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  .options {
      display: none;
      padding: 1.5rem;
      // margin-top: 20px;
  }

  /* Flex button */
  .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
  }

  .button {
      align-items: center;
      justify-content: left;
      display: flex;
      flex: 1 1 calc(50% - 5px); /* Two columns with a small gap */
      padding: 10px;
      color: #002D72;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      background: transparent;
      border-radius: 15px;
      border: 1px solid #B5B6B8;
      font-weight: 600;
      background-image: url('/hwproductselectortool/images/unchecked.svg');
      background-repeat: no-repeat;
      background-size: 28px;
      background-position: top 17px left 58px;
      flex-grow: unset;
      font-family: 'futura-pt', sans-serif;
  }

  .button-icon {
      margin-right: 45px;
      width: 40px;
      height: 40px;
      float: left;
  }

  .button:hover {
      border: 2px solid #B5B6B8;
      cursor: pointer;
  }

  .button:focus {
      border: 1px solid #B5B6B8;
      cursor: pointer;
      background-image: url('/hwproductselectortool/images/checked.svg');
  }

  .button.first-step {
      background-position: top 14px left 68px;
      padding: 45px 15px 25px;
      font-size: 22px;
  }

  .button.first-step .button-icon {
      margin-right: 15px;
  }

  .btn-primary {
      background-color: #002D72;
      color: white;
      background-image: url('/hwproductselectortool/images/btn-chev.svg');
      background-position: center right 15px;
      background-size: 15px;
      padding: 0.5rem 3rem 0.5rem 1.5rem;
      font-size: 20px;
      width: auto;
      background-repeat: no-repeat;
      margin-top: 1rem;
      font-family: 'futura-pt', sans-serif;
  }

  .btn-orange {
      background-color: #FD6A27 !important;
      border: 2px solid #FD6A27 !important;
      color: white;
      font-size: 20px;
      font-family: 'futura-pt', sans-serif;

      &:hover {
          background-color: transparent !important;
          color: #FD6A27 !important;
      }
  }

  .btn-orange-transparent {
      background-color: transparent !important;
      color: #FD6A27 !important;
      border: 2px solid #FD6A27 !important;
      font-size: 20px;
      font-family: 'futura-pt', sans-serif;
  }

  .btn-orange-transparent--arrow {
      background-image: url("/hwproductselectortool/images/right-arrow-orange.svg");
      background-repeat: no-repeat;
      background-position: right 15px center;
      background-size: 15px;
      padding: 0.5rem 3rem 0.5rem 1.5rem;
      margin-top: 1rem;
      font-family: 'futura-pt', sans-serif;
  }

  .btn-orange-transparent--arrow:hover {
      background-color: #FD6A27 !important;
      color: white !important;
      background-image: url("/hwproductselectortool/images/btn-chev.svg") !important;
  }

  #step0start {
      display: block;
      font-weight: 400;
      font-size: 18px;
      padding: 0;
  }

  #step0start h1 {
      color: #002D72;
      font-weight: 400;
      margin-bottom: 1rem;
  }

  #step0start h1 strong {
      color: #FD6A27;
      font-weight: 700;
  }

  .back-wrapper h3 {
      float: right;
      margin-top: 60px;
      font-size: 22px;
      color: #002D72;
  }

  .back-wrapper h3 strong {
      color: #FD6A27;
  }

  .back-wrapper .btn-primary {
      background-image: url('/hwproductselectortool/images/icon-chev-left.svg');
      background-position: center left 15px;
      padding: 0.5rem 1.5rem 0.5rem 3rem;
      border: none;
      border-radius: 5px;
  }

  .back-wrapper .btn-primary:hover {
      background-color: #0b5ed7 !important;
  }

  .result {
    padding: 0;
  }

  .result p {
      font-weight: 400;
      color: #262525;
      font-size: 18px;
  }

  .result h1 {
      font-weight: 700;
  }

  .result .btn-primary {
      width: 100%;
  }

  .result .btn-orange-transparent--arrow {
      width: 100%;
  }
}