// Solution sector block
.solution-sector-block {
	background-color: $success;
	padding: 8rem 5rem !important;

	li {
		list-style: none;
    background-image: url(/wp-content/themes/harrisonwipes/assets/images/secondary-list-item.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 25px;
    padding-left: 40px;
    margin-bottom: 10px;
	}

	&--primary {
		background-color: $primary;
		color: white;

		a {
			color: white !important;
		}

		h2 {
			color: white;
		}
	}
}