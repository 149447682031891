// Application
.application-outer-wrapper {
	position: relative;
	margin-top: 5rem;
  margin-bottom: 5rem;
}

.application-outer-wrapper:nth-child(odd) {
  .after {
  	width: 250px;
  	height: 250px;
  	position: absolute;
  	z-index: 0;
  	bottom: -20px;
  	left: -35px;
  	background-image: url('/wp-content/themes/harrisonwipes/assets/images/grid-left.svg');
  }
}

.application-outer-wrapper:nth-child(even) {
  .after {
  	width: 250px;
  	height: 250px;
  	position: absolute;
  	z-index: 0;
  	top: -40px;
  	right: -20px;
  	background-image: url('/wp-content/themes/harrisonwipes/assets/images/grid-right.svg');
  }
}

.application-wrapper {
	background: white;
	border-radius: 10px;
	padding: 5rem 3rem;
	margin: 3rem 0;
	position: relative;
	z-index: 1;
}

