.product-landing-page-case-studies {
	background-color: $offwhite;
	padding-top: 100px;
	padding-bottom: 100px;

	.tabs-wrap {
		padding: 15px 15px 0;
	}

	.nav-link {
		background-color: white;
		border-radius: 0 !important;
		color: $primary !important;
		margin: 0;
		text-align: left;
		padding-left: 30px;
    padding-right: 30px;
    font-family: $font-family-monospace;
    font-size: 20px;

    &:hover {
    	background-color: $primary;
    	color: white !important;
    	text-decoration: none !important;

    	svg {
    		path {
    			fill: white !important;
    		}
    	}
    }

    &.active {
    	background-color: $primary;
    	background-image: url('/wp-content/themes/harrisonwipes/assets/images/nav-tab-link-after-white.svg');
  		background-repeat: no-repeat;
  		background-size: 15px;
  		background-position: center right;
    	color: white !important;
    	text-decoration: none !important;

    	svg {
    		path {
    			fill: white !important;
    		}
    	}
    }

    span {
    	svg {
    		width: 50px;
    		height: 50px;
    		margin-right: 15px;
    	}
    }
	}

	.tab-content {
		height: 100%;
		background-color: white;
		padding: 60px;
	}

	.col-lg-6,
	.col-lg-18 {
		padding: 0;
	}

	.case-study-statistic {
		color: $orange;
		font-size: 80px;	
		font-weight: 500;	
	}

	.case-study-info {
		color: $orange;
		font-size: 30px;		
	}

	.card {
		border-radius: 0;
	}

	.card-header {
		background-color: white;
		border-radius: 0 !important;
		color: $primary !important;
		margin: 0;
		text-align: left;
		padding-left: 30px;
    padding-right: 30px;
    font-family: $font-family-monospace;
    font-size: 20px;

    &:hover {
    	background-color: $primary;
    	color: white !important;
    	text-decoration: none !important;

    	svg {
    		path {
    			fill: white !important;
    		}
    	}
    }

    &[aria-expanded="true"] {
    	background-color: $primary;
    	color: white !important;
    	text-decoration: none !important;

    	svg {
    		path {
    			fill: white !important;
    		}
    	}
    }

    span {
    	svg {
    		width: 50px;
    		margin-right: 15px;
    	}
    }
	}
}