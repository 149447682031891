// Form
.input-group:not(.has-validation) > .form-control:not(:last-child) {
	border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-append > .input-group-text {
	border-top-left-radius: 0 !important;
 	border-bottom-left-radius: 0 !important;
}

.wpcf7 {
	max-width: 100%;
  overflow: hidden;

  label {
  	display: block !important;
  }
}

.wpcf7-select {
	display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
	border-radius: 5px !important;
	font-family: "futura-pt", sans-serif !important;
	font-size: 1.115rem !important;
	line-height: 1.5 !important;
	padding: 1.5rem 1rem !important;
	color: black !important;
}

.input-group-text {
	background: $primary !important;
	border-radius: 5px !important;

	img {
		margin-bottom: 0 !important;
	}
}

.form-container {
	color: white;
	padding: 3rem 2rem;
	border-radius: 15px;

	@media (min-width: 1200px) {
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/after-shape.png');
		background-repeat: no-repeat;	
  	background-position: top 20px right 20px;
  	background-size: 550px;
	}

	&--primary {
		background-color: $primary;

		h2,h3,h4,h5 {
			color: white !important;
		}

		a {
			color: white !important;
    	font-weight: 600;	
		}

		.last {
			@media(min-width: 1200px) {
				margin-top: 58px;
			}
		}
	}

	&--secondary {
		background-color: $secondary;

		.btn-form-secondary {
			background-color: $primary !important;
			background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-arrow-icon.svg") !important;
		}

		a {
			color: white !important;
    	font-weight: 600;	
		}
	}
}

.one-half,
.one-third {
  position: relative;
  margin-right: 4%;
  float: left;
  margin-bottom: 20px;
  text-align: left;
}
 
.one-half { 
	width: 48%; 
}

.one-third { 
	width: 30.66%; 
}
 
.last {
  margin-right: 0 !important;
  clear: right;
}
 
@media only screen and (max-width: 767px) {
  .one-half, .one-third {
      width: 100%;
      margin-right: 0;
  }
}

.form-check {
	margin-bottom: 1.5rem;
	margin-top: 5rem;
}

.form-check-label {
	margin-top: -57px;
}