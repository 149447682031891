// Buttons
.btn {
	color: white !important;
	
	&-primary {
		color: white !important;

		&:focus { 
			color: white !important;
		}

		&:visited { 
			color: white !important;
		}

		&:after {
			display: none;
		}

		&--arrow {
			background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-arrow-icon.svg");
			background-repeat: no-repeat;
			background-position: right 15px center;
    	background-size: 12px;
    	padding-right: 45px !important;
		}
	}

	&-form {
		background-color: $primary !important;
		background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-arrow-icon.svg") !important;
		background-repeat: no-repeat !important;
		background-position: right 15px center !important;
  	background-size: 12px !important;
  	border: none !important;
  	border-radius: 10px !important;
  	font-family: "futura-pt", sans-serif !important; 
  	padding: 0.75rem 45px 0.75rem 1.5rem !important;
  	font-size: 1.115rem !important;
    line-height: 1.5 !important;

    &:focus { 
			color: white !important;
		}

		&:visited { 
			color: white !important;
		}

	}

	&-form-secondary {
		background-color: $secondary !important;
		background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-icon-primary.svg") !important;
		background-repeat: no-repeat !important;
		background-position: right 15px center !important;
  	background-size: 12px !important;
  	border: none !important;
  	border-radius: 10px !important;
  	font-family: "futura-pt", sans-serif !important; 
  	padding: 0.75rem 45px 0.75rem 1.5rem !important;
  	font-size: 1.115rem !important;
    line-height: 1.5 !important;

    &:focus { 
			color: white !important;
		}

		&:visited { 
			color: white !important;
		}
	}
	
	&-illus {
		background-color: white !important;
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/secondary-list-item.svg');
		background-position: left 10px center;
		background-repeat: no-repeat;
		background-size: 15px;
    border-radius: 10px !important;
    color: $primary !important;
    font-weight: 700 !important;
    padding: 8px 8px 8px 30px !important;
    font-size: 16px !important;
    border: 2px solid $primary !important;

    &:hover {
    	background-color: $secondary !important;
    }
	}

	&-department {
		background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-icon-white.svg") !important;
		background-repeat: no-repeat !important;
		background-position: right 15px center !important;
  	background-size: 12px !important;
  	padding-right: 40px !important;
	}

	&-light-green {
		background-color: $light-green !important;
	}

	&-dark-green {
		background-color: $dark-green !important;
	}

	&-gold {
		background-color: $gold !important;
	}

	&-orange {
		background-color: $orange !important;
		border: 2px solid $orange !important;
		color: white;

		&:hover {
			background-color: transparent !important;
			color: $orange !important;
		}
	}

	&-orange-transparent {
		background-color: transparent !important;
		color: $orange !important;
		border: 2px solid $orange !important;

		&--arrow {
			background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-arrow-orange.svg");
			background-repeat: no-repeat;
			background-position: right 15px center;
    	background-size: 10px;
    	padding-right: 45px !important;

    	&:hover {
    		background-color: $orange !important;
				color: white !important;
    		background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-icon-white-hover.svg") !important;
    	}
		}
	}
}