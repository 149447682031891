// Navbar
.navbar {
	background: $primary;
	width: 100%;
  position: sticky !important;
  z-index: 9999;
  top: 0px;
}

.nav {
	@media (max-width: 992px) {
		flex-direction: column;
	}
}

.navbar-nav {
	padding-left: 0 !important;
}

.nav-item {
	list-style: none;

	&.show {
		.nav-link {
			color: $secondary !important;
			&:after {
				content: '';
				height: 10px;
			  width: 100%;
			  position: absolute;
			  bottom: 0;
			  background: $secondary;
			  left: 0;
			  margin-left: 0 !important;
			}
		}
	}

	&--contact {
		background-color: $secondary;
		color: white !important;
		padding: 1rem;
		position: relative;
		font-weight: 700;
		position: absolute !important;
    right: 54px;

		&:hover {
			text-decoration: none;
		}

		&:before {
			content: '';
			position: absolute;
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/contact-nav-bg.png');
			background-size: cover;
			background-position: top left;
			background-repeat: no-repeat;
			top: 0;
			left: -100px;
			width: 100px;
			height: 100%;
		}

		&:after {
			content: '';
			position: absolute;
			background-color: $secondary;
			top: 0;
			right: -105px;
			width: 100%;
			height: 100%;
		}
	}
}

.nav-link {
	color: white !important;
	position: relative;
	margin-left: 2rem;
	margin-right: 2rem;

	&:hover {
		color: $secondary !important;
		text-decoration: underline !important;
		text-decoration-thickness: 5px !important;
		text-underline-offset: 8px !important;
	}
}

.nav-link:hover:after {
	// content: '';
	// height: 10px;
  // width: 100%;
  // position: absolute;
  // bottom: 0;
  // background: $secondary;
  // left: 0;
  // margin-left: 0 !important;
}

.dropdown-toggle:after {
	border: 0 !important;
}

.top {
  position:sticky;
  width: 100%;
  z-index: 1;
  top: 0
}

// Dropdown
.dropdown-menu {
	background-color: $success !important;
	border-radius: 0 !important;
	width: 100%;
	z-index: 99999 !important;

	@media (min-width: 992px) {
		margin-top: -2px !important;
		height: 530px;
		padding: 3rem !important;
	}

	h2 {
		color: $secondary;
	}

	a {
		color: $primary !important;

		// &:hover {
		// 	text-decoration: none;
		// }
	}

	img {
		margin-bottom: 15px;
	}

	&--sectors {
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/dropdown-right-graphic.svg');
		background-position: bottom -25px right -25px;
		background-repeat: no-repeat;
		background-size: 220px;

		@media (min-width: 992px) {
			height: 430px;
		}

		img {
			height: 60px;
			margin: 0 auto;
		}

		a {
			font-family: $font-family-monospace;
		}
	}

	&--solutions {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/solutions-menu-after.svg');
			background-size: cover;
			background-position: top left;
			background-repeat: no-repeat;
			top: -14px;
    	right: -9px;
			width: 200px;
	    height: 200px;
		}
	}

	&__top-five {
		background-color: $primary;
		color: white;
		padding: 1rem;
		border-radius: 15px;
		width: 100%;

		a {
			color: white !important;
			font-size: 18px;
		}

		h4 {
			color: white;
			margin-bottom: 0;
		}

		&__heading {
			border-right: 1px solid white;
		}
	}

	&--application {
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/application-dropdown-graphic.svg');
		background-position: bottom -25px right -25px;
		background-repeat: no-repeat;
		background-size: 220px;
	}

	&--brands {
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/brand-dropdown-graphic.svg');
		background-position: bottom -25px left -25px;
		background-repeat: no-repeat;
		background-size: 220px;
	}

	&__brand-block {
		background-color: white;
		font-size: 16px;
		padding: 2rem;
		width: 100%;

		.btn {
			color: white !important;
		}
	}
}

.dropdown-contact-us {
	display: none;
	background-color: $secondary;
	color: white;
	font-size: 15px;
	position: absolute;
  top: 58px;
  right: 0;
  width: 263px;
  z-index: 9999;
  padding: 30px;

  h4 {
  	font-size: 20px;
  }

	&.show {
		display: block !important;
	}

	&:before {
		content: "";
    position: absolute;
    left: -300px;
    top: 0;
    height: 400.5px;
    width: 400px;
    background-image: url(/wp-content/themes/harrisonwipes/assets/images/contact-dropdown-before.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
	}
}