// Two column block
.two-column-block {
	ul {
		padding-left: 0 !important;
	}

	li {
		list-style: none;
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/secondary-list-item.svg');
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 25px;
		padding-left: 40px;
		margin-bottom: 10px;
	}

	a {
		position: relative;

		&:after {
			content: "";
	    position: absolute;
	    right: -30px;
	    top: 3px;
	    height: 20px;
	    width: 20px;
	    background-image: url(/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-secondary-blue-right.svg);
	    background-size: cover;
	    background-repeat: no-repeat;
		}
	}

	a:visited {
		color: $secondary;
	}
}