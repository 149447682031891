// FAQs
.faqs {
	margin-top: 5rem;
	margin-bottom: 5rem;
	&__block {
		.card {
			border-radius: 15px;
			margin-bottom: 15px;
			overflow: hidden;
		}

		h3 {
			margin-bottom: 0;
		}

		.card-header {
			border: 0;
			background-color: white;
			position: relative;

			&:after {
				content: '';
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/arrow-up.png');
				background-size: cover;
				background-repeat: no-repeat;
				height: 50px;
				width: 50px;
				position: absolute;
				top: 0;
				right: 15px;
			}

			&.collapsed {
				&:after {
					background-image: url('/wp-content/themes/harrisonwipes/assets/images/arrow-down.png');
				}
			}

			p {
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}
}