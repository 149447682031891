// Search
.search-form {
	margin-top: 15px;
	
	label {
		float: left;
    width: 80%;
	}
}

.search-field {
	color: #666;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  font-family: "futura-pt", sans-serif !important;
  font-size: 1.115rem !important;
  line-height: 1.5 !important;
  padding: 0.5rem 1rem !important;
  width: 110%;
  float: left;
}

.search-submit {
	background-color: $primary !important;
	background-image: url('/wp-content/themes/harrisonwipes/assets/images/search-icon.svg') !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: 22px !important;
	height: 45px;
  width: 45px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}