// Containers
.container-fluid {
	&--primary {
		background-color: $primary;

		h2 {
			color: white;
		}

		&.order-free-sample {
			@include afterShape;

			@media (min-width: 768px) {
				&:before {
					content: "";
			    background-image: url(/wp-content/themes/harrisonwipes/assets/images/brands-overlay.png);
			    background-position: top left;
			    background-repeat: no-repeat;
			    height: 360px;
			    width: 360px;
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    background-size: cover;
				}
			}

			a {
				color: white !important;
				font-weight: 600;
			}

			label {
				color: white;
			}

			.btn-form {
				background-color: $secondary !important;
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/right-icon-primary.svg') !important;
			}

			.wpcf7-response-output {
				color: white !important;
			}
		}
	}

	&--secondary {
		background-color: $secondary;

		h2 {
			color: white;
		}

		.input-group-text {
			border-color: $primary;
		}

		&.order-free-sample {
			@include afterShape;

			&:before {
				content: "";
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/secondary-before-shape.svg');
				background-repeat: no-repeat;
		    position: absolute;
		    left: -10px;
		    bottom: -8px;
		    width: 400px;
    		height: 400px;
		    background-size: cover;
			}

			label {
				color: white;
			}

			a {
				color: white !important;
				font-weight: 600;
			}
		}
	}

	&--success {
		background-color: $success;
	}
}

.container {
	&--full-width {
		max-width: none !important;
	}
}