.story-download {
	padding-top: 50px;
	padding-bottom: 50px;
	
	h4 {
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		font-size: 30px;
	}

	h3 {
		font-family: $font-family-sans-serif;
		font-size: 40px;
	}
}