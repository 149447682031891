// Story
.story {
	background-image: linear-gradient(to bottom, #002d72, #004188, #00569d, #006bb2, #0081c6, #0081c6, #0081c6, #0081c6, #006bb2, #00569d, #004188, #002d72);
	color: white;
	font-size: 20px;

	h1, h2, h3, h4, h5 {
		color: white;

		strong {
			color: $secondary;
		}
	}

	h2 {
		font-size: 48px;
	}
}