.story-anchors {
	margin-top: 50px;
	margin-bottom: 50px;

	&__wrap {
		background-color: white;
		display: inline-block;
    padding: 30px 50px;
    border-radius: 20px;

    a {
    	text-transform: uppercase;
    	font-family: $font-family-monospace;
    	color: $primary !important;
    	padding: 0 80px;
    	font-size: 18px;

    	&:hover {
    		color: $secondary !important;
    		text-decoration-line: underline;
				text-decoration-thickness: 8px;
				text-underline-offset: 8px;
    	}
    }
	}

	&.sticky-top {
		top: 80px !important;
	}
}