.story-discover {
	margin-top: 100px;
	margin-bottom: 50px;
	color: white;
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	background-color: $secondary;
	border-radius: 30px;

	@media(min-width: 768px) {
		padding-left: 100px !important;
  	padding-right: 100px !important;
	}

	h1, h2, h3, h4, h5 {
		font-size: 40px;
	}

	img {
		margin-top: 50px;

		@media (min-width: 992px) {
			margin-top: 0;
			position: absolute;
    	top: -100px;
		}
	}
}