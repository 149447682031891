// People worked with
.people-worked-with {
	h2 {
		color: $secondary;
	}
	&__carousel {
		.slick-prev,
		.slick-next {
			height: 40px;
			width: 40px;
		}
		
		.slick-slide {
			padding: 50px;
		}
	}
}