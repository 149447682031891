// Timeline carousel
.timeline {
	background-color: $primary;
	color: white;
	position: relative;

	@media (min-width: 992px) {
		padding-left: 15% !important;
	}

	&:before {
		content: "";
    position: absolute;
    background-image: url(/wp-content/themes/harrisonwipes/assets/images/solutions-menu-before.svg);
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
    bottom: -15px;
    left: -8px;
    width: 200px;
    height: 200px;
    z-index: 0;
	}

	&:after {
		content: "";
    background-image: url(/wp-content/themes/harrisonwipes/assets/images/timeline-after.svg);
    background-position: top right;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    position: absolute;
    right: -15px;
    top: -22px;
    background-size: cover;
    z-index: 0;
	}

	&__carousel {
		z-index: 1;

		@media (min-width: 992px) {
			border-top: 3px solid $secondary;
		}

		.slick-prev {
			height: 40px;
			width: 40px;
		}

		.slick-next {
			height: 40px;
			width: 40px;
			right: 25px;
		}

		&__slide {
			@media (min-width: 992px) {
				padding-top: 50px;
				padding-left: 100px;
			}

			padding-bottom: 50px;
			position: relative;

			&__image {
				position:relative;

				@media (min-width: 992px) {
					&:before {
						content: '';
						height: 180px;
						width: 180px;
						background-image: url('/wp-content/themes/harrisonwipes/assets/images/timeline-image-before.svg');
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						left: -25px;
	    			top: -20px;
						position: absolute;
					}
				}
			}

			&__polaroid {
				padding: 30px 30px 60px 30px;
		    background: white;
		    width: 300px;
		    transform: rotate(-6deg);
		    margin-left: 35px;
		    height: 350px;
		    box-shadow: -8px 10px 4px black;
		    margin-top: 40px;
			}

			&__info {
				color: white;

				h2 {
					color: $secondary;
					font-size: 100px;
				}
			}
		}
	}
}

.timeline__carousel .slick-slide:nth-child(odd) {
	position: relative;

	@media (min-width: 992px) {
		&:before {
			content: '';
			height: 20%;
			width: 13px;
			background-image : url('/wp-content/themes/harrisonwipes/assets/images/timeline-short-before.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: bottom;
			left: 30px;
			top: 0px;
			position: absolute;
		}
	}
}

.timeline__carousel .slick-slide:nth-child(even) {
	position: relative;

	@media (min-width: 992px) {
		&:before {
			content: '';
			height: 70%;
			width: 13px;
			background-image : url('/wp-content/themes/harrisonwipes/assets/images/timeline-long-before.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: bottom;
			left: 30px;
			top: 0px;
			position: absolute;
		}
	}
}