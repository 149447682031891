.story-image-and-content {
	padding-top: 50px;
	padding-bottom: 50px;
	
	@media(min-width: 768px) {
		padding-left: 100px !important;
  	padding-right: 100px !important;
	}

	.lead {
		font-size: 24px;
		margin-bottom: 30px;
	}

	h1, h2, h3, h4, h5 {
		font-family: $font-family-sans-serif;

		strong {
			color: $primary;
		}
	}
}