.story-hero {
	padding: 100px 0 50px;
	text-align: center;

	h1 {
		font-size: 40px;
    margin: 5px;

    @media (min-width: 768px) {
    	font-size: 50px;
    }

    strong {
    	font-size: 24px;

    	@media (min-width: 768px) {
	    	font-size: 35px;
	    }
    }
	}

	img {
		margin-top: 50px;
	}
}