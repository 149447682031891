// Department block
.departments {
	background-color: $gray-700;

	&__block {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;

		&__image {
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
		}

		&__info {
			background-color: white;
			padding: 3rem !important;

			img {
				margin-bottom: 2rem;
			}

			&--orange {
				li {
					@include infoListItem;
				}	
			}

			&--blue {
				li {
					@include secondaryListItem;
				}				
			}
		}
	}
}