// Learning
.learning {
	background-image: url('/wp-content/themes/harrisonwipes/assets/images/learning-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: white;
	margin-top: 100px;

	h1, h2, h3, h4, h5 {
		color: white;
	}

	#celebrate-badge {
		position: absolute;
    bottom: 40px;
    left: -40px;
	}
}