// Header
.header {
	@media (max-width: 991px) {
		text-align: center;

		img {
			margin-bottom: 2rem;
		}
	}

	background: white;
	font-size: 16px;
	padding-top: 15px;
	padding-left: 0 !important;
	padding-right: 0 !important;

	.form-control {
		font-size: 16px;
	}

	a {
		color: $primary !important;
	}
}