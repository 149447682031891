.story-image-and-content-with-lead {
	padding-top: 50px;
	padding-bottom: 50px;
	
	@media(min-width: 768px) {
		padding-left: 100px !important;
  	padding-right: 100px !important;
	}

	&.white {
		margin-top: 50px;
		margin-bottom: 150px;
		background-color: white;
		border-radius: 30px;
		padding: 100px !important;
		color: #1F1F1F;

		@media(min-width: 768px) {
			padding-left: 100px !important;
	  	padding-right: 100px !important;
		}

		h1, h2, h3, h4, h5 {
			color: $primary;
		}
	}

	h2 {
		font-family: $font-family-sans-serif;

		span {
			color: $secondary;
		}
	}

	&__lead {
		font-size: 24px;
		margin-bottom: 30px;
	}

	.btn {
		margin-bottom: 30px;
	}
}