// Service blocks
.service-block {
	border-bottom: 1px solid #707070;
	&__list {
		border-radius: 10px;
		color: white;
		padding: 3rem;

		a {
			color: white !important;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				right: -30px;
		    margin-top: 6px;
		    height: 20px;
		    width: 20px;
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/carousel-arrow-right.svg');
				background-size: cover;
				background-repeat: no-repeat;
			}
		}
	}

	&--brands {
		&--primary {
			.service-block__list {
				background-color: $primary !important;
			}

			li {
				@include warningListItem;
			}
		}

		&--secondary {
			.service-block__list {
				background-color: $secondary !important;
			}

			li {
				@include warningListItem;
			}
		}

		&--gray-600 {
			.service-block__list {
				background-color: $gray-600 !important;
			}

			h2 {
				color: $warning !important;
			}

			li {
				@include warningListItem;
			}
		}
	}
}

.service-block:nth-of-type(even) {
	h2 {
		color: $secondary;
	}

	.service-block__list {
		background-color: $secondary;

		h3 {
			color: $primary;
		}

		ul {
			padding-left: 0 !important;
		}

		li {
			@include secondaryListItem;
			background-image: url(/wp-content/themes/harrisonwipes/assets/images/primary-list-item.svg) !important;
		}
	}		
}

.service-block:nth-of-type(odd) {
	h2 {
			color: $primary;
		}

		.service-block__list {
			background-color: $primary;

			h3 {
				color: $secondary;
			}

			ul {
				padding-left: 0 !important;
			}

			li {
				list-style: none;
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/secondary-list-item.svg');
				background-position: left center;
				background-repeat: no-repeat;
				background-size: 25px;
				padding-left: 40px;
    		margin-bottom: 10px;
			}
		}
}

.service-block:last-child {
	border: none;
}