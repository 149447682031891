.story-tabs {
	color: $primary;

  .story-tabs-row {
  	background-color: white;
  	border-radius: 30px;
  	border: 3px solid $secondary;
  	overflow: hidden;
  }

	h1, h2, h3, h4, h5 {
		color: $primary;
	}

	h2 {
		font-size: 30px;
		line-height: 42px;
	}

	.nav-pills {
		border-right: 3px solid $secondary;
	}

	.nav-link {
		margin: 0;
		background: transparent;
		border-radius: 0 !important;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 3px solid $secondary;
		box-shadow: none !important;
		padding: 4rem 2rem !important;
		color: $secondary !important;
		font-family: $font-family-monospace;
		font-size: 22px;
		text-align: left;

		&:hover {
			text-decoration: none !important;
			color: $primary !important;
		}

		&.active {
			color: $primary !important;
			background-color: transparent !important;
		}
	}

	span {
		display: block;
    margin-right: 15px;
    float: left;
	}

	.tab-content {
		padding: 50px;

		p {
			margin-bottom: 16px;
    	font-size: 18px;
		}
	}

	.accordion {
		.btn {
			font-family: $font-family-monospace;
			color: $primary !important;
		}
	}
}