// Hero
.hero {
	position: relative;

	@media (max-width: 768px) {
		margin-bottom: -4rem;
	}

	&__page-title {
		h1 {
			font-size: 25px !important;
			text-align: center;
		}
		@media (min-width: 768px) {
			position: absolute;
	    bottom: 50px;
	    left: 15%;
	    width: 650px;

	    h1 {
	    	color: white !important;
	    	text-shadow: -2px 2px 3px black;
	    	text-align: left;

	    	@media (min-width: 768px) {
	    		font-size: 40px !important;
	    	}

	    	@media (min-width: 1200px) {
	    		font-size: 60px !important;
	    	}
	    }
	  }
	}
}

@media (min-width: 768px) {
	.hero:before {
		content: '';
		height: 100%;
		width: 760px;
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/hero-overlay.png');
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.5;
	}
}

.hero-brands {
	@media (max-width: 768px) {
		margin-bottom: 0;
	}
}

.hero-brands:before {
	display: none;
}

.home-hero {
	background-color: $success;
	padding: 0 !important;
	position: relative;

	@media (min-width: 1500px) {
		padding: 5rem;
	}

	h1 {
		color: $secondary;
	}

	&:before {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/home-hero-before.png');
		background-position: top right;
		background-repeat: no-repeat;
		height: 300px;
    width: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: cover;
	}

	&__carousel {
		.container-fluid {
			background-size: cover;
		}
	}
}

.about-us-hero {
	background-color: $success;
	padding: 5rem;
	position: relative;

	@media (min-width: 992px) {
		background-repeat: no-repeat;
  	background-position: center;
  	background-size: cover;
	}

	h1 {
		color: $secondary;
	}
}

.contact-us-hero {
	padding-top: 50px;
	padding-bottom: 50px;

	h1 {
		font-size: 4em !important;
    border: 4px solid #009fdf;
    padding: 1rem;
    display: inline-block;
	}
}