// Download brochure
#download-brochure {
	&:hover{
		a {
			text-decoration: none !important;
		}
	}
}
.download-brochure-wrap {
	color: white !important;
	position: relative;
  margin: 0 auto;

  @media (min-width: 992px) {
  	width: 40%;
  }

	h4 {
		color: white !important;
		margin-bottom: 0;
	}

	p {
		margin-bottom: 0;
	}

	&:after {
		@media (min-width: 768px) {
			content: '';
			width: 50px;
	    height: 50px;
	    position: absolute;
	    top: 0;
			right: 0;
			background-image: url('/wp-content/themes/harrisonwipes/assets/images/download-icon.svg');
			background-size: cover;
		}
	}
}