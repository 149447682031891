// Owner intro
.owner-intro {
	&__owner {
		background-color: $secondary;
		color: white;
		padding: 120px 50px !important;
		font-weight: 600;

		&__quote {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: -60px;
    		left: -30px;
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/left-speech.svg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				height: 100px;
				width: 100px;
			}

			&:after {
				content: '';				
				position: absolute;
				bottom: -80px;
				right: 0;
				background-image: url('/wp-content/themes/harrisonwipes/assets/images/right-speech.svg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				height: 100px;
				width: 100px;
			}
		}

		&__img {
			float: left;
			img {
				max-width: 60px;
			}
		}

		&__title {
			float: left;
			padding-left: 30px;

			h6 {
				color: $primary;
				margin-bottom: 0 !important;
				&.white {
					color: white !important;
				}
			}
		}
	}
}