.product-landing-page-explore-the-range {
	background-color: $offwhite;
	padding-top: 100px;
	padding-bottom: 100px;

	h1, h2, h3, h4, h5 {
		font-family: $font-family-sans-serif;
	}

	&__tab-wrap {
		background-color: white;
		padding: 50px;
		border-radius: 30px;
	}

	.nav-tabs {
		border-color: #707070 !important;
	}

	.nav-link {
		background: transparent;
		border-radius: 0 !important;
		color: $primary !important;
		font-size: 20px !important;
		border: none !important;
		font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 26px !important;

		&.active {
			text-decoration: underline !important;
    	text-decoration-thickness: 5px !important;
    	text-underline-offset: 19px !important;
    	text-decoration-color: $orange !important;
		}

		&:hover {
			text-decoration: underline !important;
    	text-decoration-thickness: 5px !important;
    	text-underline-offset: 19px !important;
    	text-decoration-color: $orange !important;
		}
	}

	.tab-content, .card-body {
		padding: 50px 30px;

		h3 {
			font-size: 48px;
    	text-transform: uppercase;
		}

		h4, h5 {
			font-family: $font-family-monospace;
			color: $orange;
			margin-top: 15px;
			margin-bottom: 5px;
		}

		h4 {
			font-size: 26px;
		}

		.btn {
			margin-top: 15px;
		}
	}

	.explore-the-range-product {
		border: 1px solid #B7B7B7;
		border-radius: 15px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		padding: 15px;
		margin-top: 10px;
	}

	.card {
		margin-bottom: 15px;
		overflow: hidden;
	}

	.card-header {
		background: $orange;
		color: white;
    border: none;
    border-radius: 0 !important;
    padding: 1.25rem;
    position: relative;
    
    &:after {
    	content: '';
    	background-image: url("/wp-content/themes/harrisonwipes/assets/images/right-icon-white-hover.svg") !important;
	    background-repeat: no-repeat;
			background-position: center;
	  	position: absolute;
	  	right: 15px;
	  	bottom: 15px;
	  	height: 35px;
	  	width: 20px;
    }
	}

	.card [aria-expanded="true"] {
		&:after {
			transform: rotate(90deg);
		}
	}
}