// Mixins

// Center element
@mixin centerVertical {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin overlay {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.75);
	z-index: 0;
}

@mixin borderedLink {
	color: $primary !important;
	border-bottom: 1px solid $primary;
	margin-top: 1rem;
	display: inline-block;
	font-family: $font-family-monospace;
	font-size: 18px;

	&:hover {
		text-decoration: none;
		border: none;
	}
}

@mixin afterShape {
	position: relative;

	&:after {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/after-shape.png');
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		height: 180px;
    width: 180px;
    top: 0;
    right: 0;
	}
}

@mixin afterShapeSecondary {
	position: relative;

	&:after {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/aftershapesecondary.svg');
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		height: 180px;
    width: 180px;
    top: -13px;
    right: -15px;
	}
}

@mixin beforeShapeSecondary {
	position: relative;

	&:before {
		content: '';
		background-image: url('/wp-content/themes/harrisonwipes/assets/images/beforeshapesecondary.svg');
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		height: 180px;
    width: 180px;
    bottom: -13px;
    left: -15px;
	}
}
// List items
@mixin secondaryListItem {
	list-style: none;
  background-image: url(/wp-content/themes/harrisonwipes/assets/images/secondary-list-item.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-left: 40px;
  margin-bottom: 10px;
}

@mixin warningListItem {
	list-style: none;
  background-image: url(/wp-content/themes/harrisonwipes/assets/images/red-list-item.svg) !important;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-left: 40px;
  margin-bottom: 10px;
}

@mixin infoListItem {
	list-style: none;
  background-image: url(/wp-content/themes/harrisonwipes/assets/images/info-list-item.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-left: 40px;
  margin-bottom: 10px;
}