.product-landing-page-template {
	.faqs{
		&__block {
			.card-header {
				background-color: $primary;
				border-radius: 0;
				color: white;
				padding: 30px;
    		font-size: 24px;

    		p {
    			font-weight: 400 !important;
    		}

				&:after {
					background-image: url('/wp-content/themes/harrisonwipes/assets/images/minus-white-icon.svg');
					background-size: contain;
					height: 30px;
					width: 30px;
					top: 30%;
				}

				&.collapsed:after {
					background-image: url('/wp-content/themes/harrisonwipes/assets/images/plus-icon-white.svg');
				}
			}

			.card-body {
				background-color: $primary;
				color: white;
				padding: 0 30px 30px;
			}
		}
	}
}