.story-progress {
	border-radius: 30px;
	background-color: white;
	margin-top: 50px;
	margin-bottom: 50px;
	padding: 50px;
	color: $primary;
	text-align: center;

	@media(min-width: 768px) {
		padding-left: 150px !important;
  	padding-right: 150px !important;
	}

	h1, h2, h3, h4, h5 {
		color: $primary;
		font-size: 30px;
		text-transform: uppercase;
	}

	.align-self-center img {
		@media(min-width: 992px) {
			margin-top: -90px;
		}
	}
}