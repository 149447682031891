// WP Posts
.wp-block-latest-posts__post-title {
	font-size: 25px;
	font-weight: 600;
}

.wp-block-latest-posts__featured-image {
	margin-bottom: 1rem;
}

.entry-footer {
	display: none !important;
}

.post-thumbnail {
	margin-top: 2rem;

	img {
		width: 100%;
	}
}

.wp-block-latest-posts.is-grid {
	padding: 0 !important;
  margin: 0 !important;
}