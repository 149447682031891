// Sector home
.sector-illustration__slide {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	width: 100%;

	.btn-illus {
		position: absolute;
	}
}