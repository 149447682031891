// Brochure downloads
.brochure-download {
	display: inline-block;
	margin-top: 3rem;

	&__prev {
		height: 300px;
  	display: block;
  	margin: 0 auto;

  	&:hover {
			opacity: 0.75;
		}
	}
}