// Carousel
.slick-prev, .slick-next {
	width: 35px;
  height: 35px;
}

.our-products {
	margin-bottom: 3rem;

	.slick-prev, .slick-next {
		height: 50px;
		width: 50px;
	}

	.slick-prev {
		left: -70px;
	}

	.slick-next {
		right: -70px;
	}

	&__slide {
		overflow: hidden;

		@media (min-width: 1200px) {
			height: 350px;
		}

		&__image {
			background-color: $success;
			padding: 1rem;
	    background-size: 80%;
	    background-repeat: no-repeat;
	    background-position: center;
			height: 350px;
		}

		&__desc {
			background-color: white;
			padding: 2rem !important;
			
			@media (min-width: 1200px) {
				height: 350px;
			}
		}
	}
}

// Home our products
.home-our-products {
	margin-bottom: 3rem;

	.slick-prev, .slick-next {
		height: 50px;
		width: 50px;
	}

	.slick-prev {
		left: -70px;
	}

	.slick-next {
		right: -70px;
	}

	&__slide {
		overflow: hidden;

		&__image {
			background-color: rgba(0, 45, 114, 0.3);
			padding: 1rem;
		}

		&__desc {
			background-color: white;
			padding: 2rem !important;
		}
	}
}

// Applications products
.applications-products {
	.slick-prev, .slick-next {
		height: 40px;
		width: 40px;
	}

	.slick-prev {
		left: -35px;
	}

	.slick-next {
		right: -35px;
	}

	&__slide {
		padding: 1rem;

		&__card {
			border: 1px solid $success;
			overflow: hidden;
			border-radius: 15px;
			color: $primary;
			text-align: center;
			padding-bottom: 2rem;
			height: 450px;

			&__img {
				height: 300px;
		    overflow: hidden;
		    background-size: 100%;
		    background-position: center;
		    background-repeat: no-repeat;
			}

			&__info {
				background-color: $success;
				height: 150px;
				padding: 15px 5px;

				p {
					margin-bottom: 0;
					margin-top: 1rem;
				}

				a {
					@include borderedLink;
					font-family: $font-family-sans-serif !important;
				}
			}
		}
	}
}

// Product carousel
.products-carousel {
	.slick-prev, .slick-next {
		height: 50px;
		width: 50px;
		z-index: 5;
	}

	.slick-prev {
		left: -25px;
	}

	.slick-next {
		right: -25px;
	}

	&__slide {
		padding: 1rem;

		&__card {
			background-color: white;
			border-radius: 15px;
			color: $primary;
			text-align: center;
			padding: 1rem 1rem 2rem 1rem;
			height: 500px;

			h4 {
				margin-top: 1rem;
			}

			p {
				margin-bottom: 0;
			}

			a {
				@include borderedLink;
				font-family: $font-family-sans-serif !important;
			}

			img {
				height: 260px;
	    	margin: 15px auto;
			}
		}
	}
}



.product-gallery-nav {
	.slick-slide {
		margin: 15px;
	}
}

.sector-illustration {
	.slick-prev {
		left: 15px !important;
		z-index:5;
		height: 50px;
		width: 50px;
	}

	.slick-next {
		right: 15px !important;
		z-index:5;
		height: 50px;
		width: 50px;
	}
}